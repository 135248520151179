export const PRE_ORDER_PRODUCT_NOT_FOUND = 'preorder_product_not_found';

export const MERCHANT_NOT_ELIGIBLE = 'merchant_not_eligible_for_the_feature';

export const MIN_PRE_ORDER_QUANTITY = 'min_pre_order_quantity';

export const PRE_ORDER_REQUEST_NOT_FOUND = 'preorder_request_not_found';

export const MERCHANT_WALLET_NOT_FOUND = 'merchant_wallet_not_found';

export const NO_OFFERED_DOWN_PAYMENT = 'no_offered_down_payment';

export const ILLEGAL_PRE_ORDER_STATE_CHANGE = 'illegal_pre_order_state_change';

export const NO_ENOUGH_MONEY_FOR_PRE_ORDER = 'no_enough_money_for_preorder';

export const PRE_ORDER_ERRORS_MAP = new Map<string, string>([
  [PRE_ORDER_PRODUCT_NOT_FOUND, 'PRE_ORDER_DIALOG.ERRORS.PRE_ORDER_PRODUCT_NOT_FOUND'],
  [PRE_ORDER_REQUEST_NOT_FOUND, 'PRE_ORDER_DIALOG.ERRORS.GENERAL_ERROR'],
  [MERCHANT_NOT_ELIGIBLE, 'PRE_ORDER_DIALOG.ERRORS.MERCHANT_NOT_ELIGIBLE'],
  [MIN_PRE_ORDER_QUANTITY, 'PRE_ORDER_DIALOG.ERRORS.MIN_PRE_ORDER_QUANTITY'],
  [MERCHANT_WALLET_NOT_FOUND, 'CONFIRM_PRE_ORDER_OFFER_DIALOG.ERRORS.MERCHANT_WALLET_NOT_FOUND'],
  [NO_OFFERED_DOWN_PAYMENT, 'CONFIRM_PRE_ORDER_OFFER_DIALOG.ERRORS.NO_OFFERED_DOWN_PAYMENT'],
  [
    ILLEGAL_PRE_ORDER_STATE_CHANGE,
    'CONFIRM_PRE_ORDER_OFFER_DIALOG.ERRORS.ILLEGAL_PRE_ORDER_STATE_CHANGE',
  ],
  [
    NO_ENOUGH_MONEY_FOR_PRE_ORDER,
    'CONFIRM_PRE_ORDER_OFFER_DIALOG.ERRORS.NO_ENOUGH_MONEY_FOR_PRE_ORDER',
  ],
]);
