<div class="pre-order-rules-wrapper">
  <div class="pre-order-rules-header">
    <div class="pre-order-rules-title-row">
      <h2>{{ 'PRE_ORDER_PAGE.RULES.TITLE' | translate }}</h2>
      <div class="close">
        <img src="/assets/img/close.svg" (click)="onDismiss()" />
      </div>
    </div>
    <hr />
  </div>

  <div
    #preorderRulesBody
    class="pre-order-rules-body pre-order-rules-content-wrapper body2--medium"
  >
    <p class="pre-order-rules-title" class="body1--bold">
      {{ 'PRE_ORDER_PAGE.RULES.RESERVATION_TERMS_CONDITIONS' | translate }}
    </p>
    <ul class="pre-order-rules-content-points">
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_1' | translate }}
      </li>
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_2' | translate }}
      </li>
      <table class="inner-border">
        <thead>
          <tr>
            <th class="body2--medium">
              {{ 'PRE_ORDER_PAGE.RULES.TABLE1_HEADER1' | translate }}
            </th>
            <th class="body2--medium">
              {{ 'PRE_ORDER_PAGE.RULES.TABLE1_HEADER2' | translate }}
            </th>
          </tr>
        </thead>
        <tr>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE1_DATA1' | translate }}
          </td>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE1_DATA5' | translate }}
          </td>
        </tr>
        <tr>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE1_DATA2' | translate }}
          </td>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE1_DATA6' | translate }}
          </td>
        </tr>
        <tr>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE1_DATA3' | translate }}
          </td>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE1_DATA7' | translate }}
          </td>
        </tr>
        <tr>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE1_DATA4' | translate }}
          </td>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE1_DATA8' | translate }}
          </td>
        </tr>
      </table>
      <li class="top-space">
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_3' | translate }}
      </li>
    </ul>
    <p class="pre-order-rules-title" class="body1--bold">
      {{ 'PRE_ORDER_PAGE.RULES.PAYMENT_TERMS' | translate }}
    </p>
    <ul class="pre-order-rules-content-points">
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_4' | translate }}
      </li>
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_5' | translate }}
      </li>
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_6' | translate }}
      </li>
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_7' | translate }}
      </li>
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_8' | translate }}
      </li>
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_9' | translate }}
      </li>
    </ul>
    <p class="pre-order-rules-title" class="body1--bold">
      {{ 'PRE_ORDER_PAGE.RULES.AVAILABILITY_DATES' | translate }}
    </p>
    <ul class="pre-order-rules-content-points">
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_10' | translate }}
      </li>
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_11' | translate }}
      </li>
      <li>
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_12' | translate }}
      </li>
      <table class="inner-border">
        <thead>
          <tr>
            <th class="body2--medium">
              {{ 'PRE_ORDER_PAGE.RULES.TABLE2_HEADER1' | translate }}
            </th>
            <th class="body2--medium">
              {{ 'PRE_ORDER_PAGE.RULES.TABLE2_HEADER2' | translate }}
            </th>
            <th class="body2--medium">
              {{ 'PRE_ORDER_PAGE.RULES.TABLE2_HEADER3' | translate }}
            </th>
            <th class="body2--medium">
              {{ 'PRE_ORDER_PAGE.RULES.TABLE2_HEADER4' | translate }}
            </th>
          </tr>
        </thead>
        <tr>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE2_DATA1' | translate }}
          </td>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE2_DATA2' | translate }}
          </td>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE2_DATA3' | translate }}
          </td>
          <td class="body2--medium numeric-cell">
            {{ 'PRE_ORDER_PAGE.RULES.TABLE2_DATA4' | translate }}
          </td>
        </tr>
      </table>
      <li class="top-space">
        {{ 'PRE_ORDER_PAGE.RULES.TEXT_13' | translate }}
      </li>
    </ul>
    <div
      *ngIf="preorderV2FeatureEnabled"
      class="pre-order-rules-content-points"
      #preorderRulesFooter
    >
      <p class="pre-order-rules-title" class="body1--bold">
        {{ 'PRE_ORDER_PAGE.RULES.PAYMENT_INSTRUCTIONS' | translate }}
      </p>
      <li>{{ 'PRE_ORDER_PAGE.RULES.WALLET_TOPUP.IBAN' | translate }}</li>
      <li>{{ 'PRE_ORDER_PAGE.RULES.WALLET_TOPUP.ACCOUNT_NUMBER' | translate }}</li>
      <li>{{ 'PRE_ORDER_PAGE.RULES.WALLET_TOPUP.SWIFT' | translate }}</li>
      <li>{{ 'PRE_ORDER_PAGE.RULES.WALLET_TOPUP.NAME' | translate }}</li>
      <li>{{ 'PRE_ORDER_PAGE.RULES.WALLET_TOPUP.BANK_NAME' | translate }}</li>
    </div>
  </div>

  <div class="pre-order-rules-footer">
    <hr />
    <button class="btn btn-approve" (click)="onAcceptRules()" [disabled]="!isApproveButtonEnabled">
      {{ 'PRE_ORDER_PAGE.RULES.I_AGREE' | translate }}
    </button>
  </div>
</div>
