import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { PreOrderSuccessfulDialogComponent } from '../../products/bulk-pre-order/pre-order-successful-dialog/pre-order-successful-dialog.component';
import { FEATURE_FLAGS } from '../../shared/constants';
import {
  PRODUCT_AVAILABLE_IN_MAX_DAYS,
  PRODUCT_AVAILABLE_IN_MIN_DAYS,
} from '../../shared/constants/feature-flags';

@Component({
  selector: 'app-pre-order-rules-dialog',
  templateUrl: './pre-order-rules-dialog.component.html',
  styleUrls: ['./pre-order-rules-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, TranslateModule],
})
export class PreOrderRulesDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('preorderRulesBody', { static: true })
  private _preorderRulesBody: ElementRef<HTMLDivElement>;

  @ViewChild('preorderRulesFooter')
  private _preorderRulesFooter: ElementRef<HTMLDivElement>;

  public isApproveButtonEnabled = false;

  public preorderV2FeatureEnabled = false;

  public penaltyList = [];

  public minDays: number;

  public maxDays: number;

  private _preorderRulesFooterObserver: IntersectionObserver;

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private _dialogRef: MatDialogRef<PreOrderSuccessfulDialogComponent>,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
  ) {}

  ngOnInit(): void {
    // This is here to generate a scroll event that will trigger the Approve button's state update
    const body = document.getElementsByClassName('pre-order-rules-body')[0];
    body.scrollTop = 1;
    body.scrollTop = 0;

    combineLatest({
      preorderV2FeatureFlag: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PRE_ORDER_V2),
      minimumNumberOfDays: this._getFeatureAttributeUsecase.execute(PRODUCT_AVAILABLE_IN_MIN_DAYS),
      maximumNumberOfDays: this._getFeatureAttributeUsecase.execute(PRODUCT_AVAILABLE_IN_MAX_DAYS),
    }).subscribe({
      next: ({ preorderV2FeatureFlag, minimumNumberOfDays, maximumNumberOfDays }) => {
        this.preorderV2FeatureEnabled = preorderV2FeatureFlag;
        if (this.preorderV2FeatureEnabled) {
          this.minDays = minimumNumberOfDays;
          this.maxDays = maximumNumberOfDays;
        } else {
          this.minDays = 2;
          this.maxDays = 3;
        }
      },
    });
  }

  ngAfterViewInit(): void {
    this._listenForScrollEvent();
  }

  ngOnDestroy(): void {
    this._preorderRulesFooterObserver.disconnect();
  }

  private _listenForScrollEvent(): void {
    this._preorderRulesFooterObserver = new IntersectionObserver(
      (entries) => {
        for (let i = 0; i < entries.length; i++) {
          const intersectionEntry = entries[i];
          if (intersectionEntry.isIntersecting) {
            this.isApproveButtonEnabled = true;
          } else {
            this.isApproveButtonEnabled = false;
            break;
          }
        }
      },
      { threshold: 0.1, root: this._preorderRulesBody.nativeElement },
    );
    this._listenForLastVisibleElement();
  }

  private _listenForLastVisibleElement(): void {
    this._preorderRulesFooterObserver.observe(this._preorderRulesFooter.nativeElement);
  }

  onDismiss(): void {
    this._dialogRef.close();
  }

  onAcceptRules(): void {
    this._dialogRef.close({ accepted: true });
  }
}
